function listGeneral(list) {
	const { points, cost, children, alerts } = list;

	// Check points
	if (cost > points) {
		alerts.push({ message: "Total list cost exceeds the selected points limit." })
	}

	let divinityCount = 0;
	let sepulcherCount = 0;
	let fireMaharajah = 0;
	let airMaharajah = 0;
	const upgrades = ["heirlooms", "mutations", "relics", "trove-finds", "artefacts", "treasures", "bestowed-relics", "modifications", "patron-s-gifts", "disciplines"];
	const upgradeMap = {};
	const masteries = ["masteries", "dark-blessings"];
	const masteriesMap = {};
	const selectionsMap = {};
	const warlordSlugs = [];
	const warlordRoles = [];
	const allyRoles = [];
	let theists = 0;
	let orders = 0;

	let kaChars = 0;
	let chiChars = 0;
	let fuChars = 0;
	let suiChars = 0;
	let muChars = 0;

	let kaItems = 0;
	let chiItems = 0;
	let fuItems = 0;
	let suiItems = 0;
	let muItems = 0;


	children.forEach(w => {
		const characterNode = w.children.find(c => c.type === "character");
		const character = characterNode.listEntry;
		if (character.isWarlord) warlordSlugs.push(character.slug);

		if (["theist-priest", "crusading-noble-lord"].includes(character.slug)) {
			theists++;
			if (character.isWarlord) warlordRoles.push("theists");
			if (characterNode.selections.includes("dynastic-ally")) allyRoles.push("theists");
		} else if (["chapter-mage", "imperial-officer"].includes(character.slug)) {
			if (character.isWarlord) warlordRoles.push("imperial");
			if (characterNode.selections.includes("dynastic-ally")) allyRoles.push("imperial");
		} else if (["noble-lord", "mounted-noble-lord"].includes(character.slug)) {
			if (character.isWarlord) warlordRoles.push("feudal");
			if (characterNode.selections.includes("dynastic-ally")) allyRoles.push("feudal");
		} else if (["errant-of-the-order-of-the-shield", "priory-commander-crimson-tower", "priory-commander-sealed-temple"].includes(character.slug)) {
			orders++;
			if (character.isWarlord) warlordRoles.push("orders");
			if (characterNode.selections.includes("dynastic-ally")) allyRoles.push("orders");
		} else if (["daimyo"].includes(character.slug)) {
			++kaChars;
		} else if (["oyabun"].includes(character.slug)) {
			++chiChars;
		} else if (["kitsune-bakasu"].includes(character.slug)) {
			++fuChars;
		} else if (["kitsune-onmyoji"].includes(character.slug)) {
			++suiChars;
		} else if (["jorogumo-mahotsu", "jorogumo-geisha"].includes(character.slug)) {
			++muChars;
		}

		characterNode.selections.forEach(s => {
			if (["way-of-the-kensei", "hannya", "kenshibu-dancer"].includes(s)) ++kaItems;
			else if (["unyielding-kata", "trial-of-hakari-ishi", "takigyo-training"].includes(s)) ++chiItems;
			else if (["gunbai", "hauchiwa", "horagai-master"].includes(s)) ++fuItems;
			else if (["kabuki", "shamisen", "fudoshin"].includes(s)) ++suiItems;
			else if (["purification-rites", "flow", "ofuda"].includes(s)) ++muItems;
		})


		if (character.slug === "fallen-divinity") {
			divinityCount++;
			if (!character.isWarlord) {
				alerts.push({ message: "The Fallen Divinity must always be selected to be the Warlord." });
			}
		} else if (character.slug === "maharajah") {
			if (characterNode.selections.includes("court-of-fire")) fireMaharajah++;
			if (characterNode.selections.includes("court-of-air")) airMaharajah++;
		}

		const regiments = w.children.filter(c => c.type !== "character");
		regiments.forEach(r => {
			if (r.listEntry.slug === "profane-sepulcher") sepulcherCount += 1;

			r.selections.forEach(s => {
				selectionsMap[s] = selectionsMap[s] ? selectionsMap[s] + 1 : 1;
			})
		})

		const { groups = [] } = character.options;
		const upgradeGroups = groups.filter(g => upgrades.includes(g.slug)) || [];
		upgradeGroups.forEach(u => {
			const { groups: subgroups = [], orphans = [] } = u;
			orphans.forEach(o => {
				if (o.selected) {
					upgradeMap[o.name] = upgradeMap[o.name] ? upgradeMap[o.name] + 1 : 1;
				}
			});

			subgroups.forEach(g => {
				g.orphans.forEach(o => {
					if (o.selected) {
						upgradeMap[o.name] = upgradeMap[o.name] ? upgradeMap[o.name] + 1 : 1;
					}
				});
			});
		})

		const masteriesGroup = groups.find(g => masteries.includes(g.slug)) || {};
		const { groups: subgr = [], orph = [] } = masteriesGroup;
		orph.forEach(o => {
			if (o.selected) {
				masteriesMap[o.name] = masteriesMap[o.name] ? masteriesMap[o.name] + 1 : 1;
			}
		});

		subgr.forEach(g => {
			g.orphans.forEach(o => {
				if (o.selected) {
					masteriesMap[o.name] = masteriesMap[o.name] ? masteriesMap[o.name] + 1 : 1;
				}
			});
		});
	});

	if (kaItems > kaChars) {
		alerts.push({ message: "Ka Disciplines have exceeded the number of Ka Characters." });
	}
	if (chiItems > chiChars) {
		alerts.push({ message: "Chi Disciplines have exceeded the number of Chi Characters." });
	}
	if (fuItems > fuChars) {
		alerts.push({ message: "Fu Disciplines have exceeded the number of Fu Characters." });
	}
	if (suiItems > suiChars) {
		alerts.push({ message: "Sui Disciplines have exceeded the number of Sui Characters." });
	}
	if (muItems > muChars) {
		alerts.push({ message: "Mu Disciplines have exceeded the number of Mu Characters." });
	}

	if (theists > 0 && orders > 0) {
		alerts.push({ message: "The Army List cannot include a [Theist] [Character] and a [The Orders] [Character] at the same time." });
	}

	const rolesIntersection = warlordRoles.filter(r => allyRoles.includes(r))
	if (rolesIntersection.length > 0) {
		alerts.push({ message: "A \"Dynastic Ally\" cannot share the same Sub-Faction Battlefield Role with the Warlord." });
	}
	if (allyRoles.length > 1) {
		alerts.push({ message: "Only one Character can be selected to be a \"Dynastic Ally\"." });
	}
	// if (list.factionSlug === "the-hundred-kingdoms") {
	// 	const countLords = warlordSlugs.filter(s => ["noble-lord", "mounted-noble-lord"].includes(s)).length;
	// 	const countPriories = warlordSlugs.filter(s => ["priory-commander-sword", "priory-commander-crimson-tower", "priory-commander-sealed-temple"].includes(s)).length;
	// 	const countPriests = warlordSlugs.filter(s => s === "theist-priest").length;
	// 	const countOfficers = warlordSlugs.filter(s => s === "imperial-officer").length;
	// 	const countMages = warlordSlugs.filter(s => s === "chapter-mage").length;

	// 	const warlordCount =
	// 		(countLords > 0 ? 1 : 0) +
	// 		(countPriories > 0 ? 1 : 0) +
	// 		(countPriests > 0 ? 1 : 0) +
	// 		(countOfficers > 0 ? 1 : 0) +
	// 		(countMages > 0 ? 1 : 0);

	// 	if (warlordCount < 1 && children.length > 0) {
	// 		alerts.push({ message: "A Character must be selected to be the Warlord." })
	// 	}
	// 	if (warlordCount > 2) {
	// 		alerts.push({ message: "Up to two Characters can be selected as Warlords." })
	// 	}

	// 	if (countLords > 1) {
	// 		alerts.push({ message: "Only one Noble Lord can be selected as Warlord." })
	// 	}

	// 	if (countPriories > 1) {
	// 		alerts.push({ message: "Only one Priory Commander can be selected as Warlord." })
	// 	}

	// 	if (countPriests > 1) {
	// 		alerts.push({ message: "Only one Theist Priest can be selected as Warlord." })
	// 	}

	// 	if (countOfficers > 1) {
	// 		alerts.push({ message: "Only one Imperial Officer can be selected as Warlord." })
	// 	}

	// 	if (countMages > 1) {
	// 		alerts.push({ message: "Only one Chapter Mage can be selected as Warlord." })
	// 	}
	// } else {
		const warlordCount = warlordSlugs.length;

		if (warlordCount < 1 && children.length > 0) {
			alerts.push({ message: "A Character must be selected to be the Warlord." })
		}
		if (warlordCount > 1) {
			alerts.push({ message: "Only one Character can be selected to be the Warlord." })
		}
	// }

	if (divinityCount > 1) {
		alerts.push({ message: "Only one Fallen Divinity can be added to the list." });
	}

	if (fireMaharajah > 1) {
		alerts.push({ message: "Only one Maharajah from the Court of Fire can be added to the list." });
	}

	if (airMaharajah > 1) {
		alerts.push({ message: "Only one Maharajah from the Court of Air can be added to the list." });
	}

	if (sepulcherCount > 1) {
		alerts.push({ message: "Only one Profane Sepulcher can be added to the list." });
	}

	const keys = Object.keys(upgradeMap);
	keys.forEach(k => {
		if (upgradeMap[k] > 1) {
			alerts.push({ message: `${k} can only appear once in the Army.` });
		}
	});

	const mkeys = Object.keys(masteriesMap);
	const exceptions = [
		"Apex Master",
		"Tontorr Rider",
		"Drum Beast Rider",
		"Hellbringer Sorcerer",
		"Ferric Throne",
		"Master of Flesh",
	];
	mkeys.forEach(k => {
		if (masteriesMap[k] > 1 && !exceptions.includes(k)) {
			alerts.push({ message: `${k} can only appear once in the Army.` });
		}
	});

	// TODO: Names should not be defined here, but retrieved from the faction data.
	// It can be done by digging through the list entry but it's too much trouble.
	// Consider moving all options outside the entries like the entries themselves
	// compared to the entrygroups. The problem with that is that they would need
	// to have unique names.
	const onePerListOptions = [
	];

	onePerListOptions.forEach(o => {
		if (selectionsMap[o.slug] > 1) {
			alerts.push({ message: `${o.name} can only appear once in the Army.` });
		}
	})
}


export default listGeneral;