import { Typography } from "@mui/material";
import FrameDialog from "../ui/containers/FrameDialog";
import FrameDialogContent from "../ui/containers/FrameDialogContent";
import QrReader from "react-qr-reader-es6";
import FloatingActions from "../ui/FloatingActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeScanner } from "../store/utils-slice";
import CloseIcon from '@mui/icons-material/Close';
import useQuery from "../hooks/use-query";
import FrameHeader from "../ui/containers/FrameHeader";
import useGameSettings from "../hooks/use-game-settings";


function ScannerDialog() {
	const open = useSelector(state => state.utils.scanOpen);
	const dispatch = useDispatch();
	const [scannerMessage, setScannerMessage] = useState("");
	const sendQuery = useQuery();
	const { getGameSettings } = useGameSettings();
	const { currentGameSlug } = useSelector(state => state.games);
	const { qrcodes, qrDirectives, mediaDirectives, entries: factionEntries } = getGameSettings();

	function handleScanData(data) {
		if (data?.length === 10) {
			const details = qrcodes[data];
			if (details) {
				let entrySlug = null;
				if (details.slug) {
					entrySlug = details.slug;
				} else if (details.slugs) {
					for (const slug of details.slugs) {
						if (factionEntries[slug]) {
							entrySlug = slug;
							break;
						}
					}
				}
				sendQuery({
					label: "",
					request: {
						type: "entries",
						factionSlug: details.factionSlug,
						slug: entrySlug,
					}
				});
				handleClose();
			} else {
				setScannerMessage("No entry was found with this QR code.")
			}
		} else if (data?.length === 8) {
			if (data.startsWith("MED")) {
				const result = mediaDirectives[currentGameSlug][data];
				if (result && result.type === "link") {
					const newWindow = window.open(result.url, '_blank', 'noopener,noreferrer');
					if (newWindow) newWindow.opener = null;
				} else {
					sendQuery({
						label: "",
						request: {
							type: "directives",
							slug: "forceerror",
						}
					});
				}
				handleClose();
			} else {
				const result = qrDirectives[currentGameSlug][data];
				if (result) {
					sendQuery({
						label: "",
						request: {
							type: "directives",
							slug: result.slug,
						}
					});
				} else {
					sendQuery({
						label: "",
						request: {
							type: "directives",
							slug: "forceerror",
						}
					});
				}
				handleClose();
			}
		}
	}

	function handleClose() {
		setScannerMessage("")
		dispatch(closeScanner());
	}

	function handleScanError() {
		setScannerMessage("Something went wrong. Please try again.")
	}

	const actions = [
		{
			id: "close",
			icon: CloseIcon,
			handler: handleClose,
		}
	];

	return (
		<FrameDialog open={open} responsive onClose={handleClose} actions={actions}>
			<FrameHeader title="Scan Card" />
			<FrameDialogContent>
				<QrReader
					delay={300}
					onError={handleScanError}
					onScan={handleScanData}
				/>
				<Typography align="center">
					{scannerMessage}
				</Typography>
			</FrameDialogContent>
		</FrameDialog>
	);
}


export default ScannerDialog;