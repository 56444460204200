import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";
import useGameSettings from "../hooks/use-game-settings";
import { getGames } from "../lib/api";
import { setGames } from "../store/games-slice";
import MainLayout from "../ui/layouts/MainLayout";


function Init() {
	const loadedGames = useLoaderData();
	const dispatch = useDispatch();
	const {
		getSupportedGameSlugs,
		getSupportedFactionSlugs
	} = useGameSettings();
	const { availableGames } = useSelector(state => state.games);

	useEffect(() => {
		const finalGames = [];
		const supportedGameSlugs = getSupportedGameSlugs();
		const availableGames = loadedGames.filter(g => supportedGameSlugs.includes(g.slug));
		availableGames.forEach(g => {
			const supportedFactions = getSupportedFactionSlugs(g.slug);

			finalGames.push({
				...g,
				factions: g.factions.filter(f => supportedFactions.includes(f.slug))
			})
		});

		dispatch(setGames(finalGames));
	}, [loadedGames, dispatch]);

	if (!availableGames?.length) return;

	return <MainLayout />
}


export function loader() {
	return getGames();
}


export default Init;