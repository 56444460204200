import { useDispatch, useSelector } from "react-redux";
import { addQuery } from "../store/queries-slice";


function useQuery() {
	const dispatch = useDispatch();
	const gameSlug = useSelector(state => state.games.currentGameSlug);
	const factionSlug = useSelector(state => state.games.currentFactionSlug);

	const sendQuery = (query) => {
		dispatch(addQuery({
			label: query.label,
			request: {
				gameSlug,
				factionSlug,
				...query.request,
			}
		}));
	}

	return sendQuery;
}


export default useQuery;