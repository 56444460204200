import { getNode } from "../../lib/list";

function getOptions(group, slugs) {
	const { orphans = [], groups = [] } = group;
	const options = [];

	options.push(...orphans.filter(o => slugs.includes(o.slug)));
	groups.forEach(g => {
		options.push(...getOptions(g, slugs))
	});

	return options;
}

function getOptionGroups(groups, slugs) {
	const results = [];

	groups.forEach(g => {
		if (slugs.includes(g.slug)) results.push(g);
		if (g.groups) {
			results.push(...getOptionGroups(g.groups, slugs));
		}
	});

	return results;
}

function getTag(groups, slug) {
	for (const g of groups) {
		const tag = g.tags?.find(t => t.slug === slug);
		if (tag) return tag;
	}
	return null;
}


function setGroupEnabled(group, enabled) {
	const { orphans = [] } = group;
	group.active = enabled;
	orphans.forEach(o => o.active = enabled);
}

function operate(target, params, listEntry) {
	const { operation, value } = params;

	if (operation === "add") {
		return target + value;
	} else if (operation === "add-per-unit") {
		return target + value * listEntry.size;
	}
}

const TLAOK_EFFECTS = {
	"MODIFY_SIZE": (node, params, source) => {
		if ([
			"flogobollon",
			"skorpios",
			"oni-ayakashi",
			"kami-ayakashi",
			"tengu-bushi",
			"tengu-ayakashi",
			"kappa-bushi",
			"origami-warriors",
			"oni-ayakashi-leader",
			"kami-ayakashi-leader",
			"tengu-bushi-leader",
			"tengu-ayakashi-leader",
			"kappa-bushi-leader",
			"origami-warriors-leader"
		].includes(source.slug)) {
			node.listEntry.size += source.size;
		} else {
			node.listEntry.size = operate(node.listEntry.size, params);
		}
	},
	"ENABLE_OPTIONS": (node, params) => {
		const { slugs, enabled } = params;
		if (node.listEntry.options) {
			const options = getOptions(node.listEntry.options, slugs);
			options.forEach(o => o.active = enabled);
		}
	},
	"ENABLE_CONQUEST_FOR_WARLORD": (node, params) => {
		const { listEntry } = node;
		if (listEntry.isWarlord) {
			const options = getOptions(listEntry.options, "conquest");
			options.forEach(o => o.active = true);
		}
	},
	"ENABLE_OPTION_GROUPS": (node, params) => {
		const { slugs, enabled } = params;
		const { listEntry } = node;
		if (listEntry.options.groups) {
			const groups = getOptionGroups(listEntry.options.groups, slugs);
			groups.forEach(g => setGroupEnabled(g, enabled));
		}
	},
	"MODIFY_OPTION_GROUP": (node, params) => {
		const { groups = [] } = node.listEntry.options;
		const { slug, minOptions, maxOptions } = params;

		const group = groups.find(g => g.slug === slug)
		if (group) {
			if (minOptions) {
				group.minOptions = operate(group.minOptions, minOptions);
			}
			if (maxOptions) {
				group.maxOptions = operate(group.maxOptions, maxOptions);
			}
		}
	},
	"MODIFY_OPTION_COST": (node, params) => {
		const { groupSlugs = [], optionSlugs = [], action } = params;
		const { listEntry } = node;

		function modify(option) {
			if (action === "DOUBLE") option.cost *= 2;
		}

		if (groupSlugs.length) {
			listEntry.options.groups.filter(g => groupSlugs.includes(g.slug)).forEach(g => {
				const { orphans = [] } = g;
				orphans.forEach(o => modify(o));
			})
		} else if (optionSlugs.length) {
			listEntry.options.orphans.filter(o => optionSlugs.includes(o.slug)).forEach(o => o.cost = operate(o.cost, params, listEntry));
		}
	},
	"ADD_TAGS": (node, params, source, list) => {
		const { listEntry } = node;
		const { tagGroup, tags = [], profileName } = params;

		if (!tagGroup || tags.length <= 0) return;

		// findGroup
		// if not, create it and push tags
		// if exists, look for tags

		if (!listEntry.profiles && profileName) return;

		let profileIndex = null;
		if (listEntry.profiles) {
			if (profileName) {
				profileIndex = listEntry.profiles.findIndex(p => p.profileName === profileName);
				if (profileIndex < 0) {
					return;
				}
			} else {
				profileIndex = 0;
			}
		}

		const tagGroups = listEntry?.tagGroups || listEntry?.profiles?.[profileIndex]?.tagGroups || node.tagGroups;

		const group = tagGroups?.find(g => g.slug === tagGroup.slug);
		if (group) {
			tags.forEach(t => {
				const existingTag = group.tags.find(e => e.slug === t.slug)
				if (existingTag && existingTag.variable && t.variable) {
					existingTag.variable = Math.max(existingTag.variable, t.variable);
				} else if (!existingTag) {
					if (t.slug === "wizard" && group.tags.find(e => e.slug === "priest")) return;
					group.tags.push(t);
				}
			})
		} else {
			const newGroup = { ...tagGroup };
			newGroup.tags = JSON.parse(JSON.stringify(tags));
			if (listEntry) {
				if (listEntry.profiles) {
					listEntry.profiles.forEach(p => {
						p.tagGroups ||= [];
						p.tagGroups.push(newGroup);
					});
				} else {
					listEntry.tagGroups ||= [];
					listEntry.tagGroups.push(newGroup);
				}
			} else {
				node.tagGroups = [newGroup];
			}
		}
	},
	"REMOVE_TAGS": (node, params, source, list) => {
		const { listEntry } = node;
		const { tagGroup, tags = [], profileName } = params;

		if (!tagGroup || tags.length <= 0) return;

		if (!listEntry.profiles && profileName) return;

		let profileIndex = null;
		if (listEntry.profiles) {
			if (profileName) {
				profileIndex = listEntry.profiles.findIndex(p => p.profileName === profileName);
				if (profileIndex < 0) {
					return;
				}
			} else {
				profileIndex = 0;
			}
		}

		const tagGroups = listEntry?.tagGroups || listEntry?.profiles?.[profileIndex]?.tagGroups || node.tagGroups;

		const group = tagGroups?.find(g => g.slug === tagGroup.slug);
		if (group) {
			group.tags = group.tags?.filter(t => !tags.includes(t.slug));
		}
	},
	"REPLACE_TAGS": (node, params) => {
		const { listEntry } = node;
		const { tagGroup, tags = [] } = params;

		if (!tagGroup || tags.length <= 0) return;

		// findGroup
		// if not, create it and push tags
		// if exists, look for tags

		const group = listEntry ? listEntry.tagGroups?.find(g => g.slug === tagGroup.slug) : node.tagGroups?.find(g => g.slug === tagGroup.slug);
		if (group) {
			group.tags = tags;
		} else {
			const newGroup = { ...tagGroup };
			newGroup.tags = tags;
			if (listEntry) {
				listEntry.tagGroups ||= [];
				listEntry.tagGroups.push(newGroup);
			} else {
				// TODO: tagGroups on the node needs reworking. This implementation
				// does not take into account potentially existing tags.
				node.tagGroups = [newGroup];
			}
		}
	},
	"REMOVE_TAGGROUPS": (node, params) => {
		const { listEntry } = node;
		const { slugs = [] } = params;

		listEntry.tagGroups ||= [];
		listEntry.tagGroups = listEntry.tagGroups.filter(g => !slugs.includes(g.slug));
	},
	"MODIFY_TAG": (node, params) => {
		const { tagGroups = [] } = node.listEntry;
		const { slug, variable, range, tags, query } = params;

		const tag = getTag(tagGroups, slug);
		if (tag) {
			if (variable) {
				tag.variable = operate(tag.variable, variable);
			}
			if (range) {
				tag.range = operate(tag.range, range);
			}
			if (tags) {
				tag.tags ||= [];
				tag.tags.push(...tags);
			}
			if (query) {
				tag.query = query;
			}
		}
	},
	"MODIFY_TYPE": (node, params) => {
		const { listEntry } = node;
		const { newType, newClass } = params;

		if (newType) listEntry.type = newType;
		if (newClass) listEntry.gameClass = newClass;
	},
	"MODIFY_STAT": (node, params) => {
		const { listEntry } = node;
		const { key, operation, variable, min, max } = params;

		function limit(a) {
			if (a < min) return min;
			if (a > max) return max;
			return a;
		}

		const statlines = [];
		if (listEntry.profiles) {
			listEntry.profiles.forEach(profile => {
				statlines.push(profile.statline);
			});
		} else {
			statlines.push(listEntry.statline);
		}

		statlines.forEach(statline => {
			if (isNaN(statline[key])) statline[key] = 0;

			if (operation === "add") {
				statline[key] = limit(statline[key] + variable);
			} else if (operation === "subtract") {
				statline[key] = limit(statline[key] - variable);
			} else if (operation === "multiply") {
				statline[key] = limit(statline[key] * variable);
			} else if (operation === "divide") {
				statline[key] = limit(statline[key] / variable);
			}
		});
	},
	"SET_STATS": (node, params) => {
		const { listEntry } = node;

		for (const stat in params) {
			listEntry.statline[stat] = params[stat];
		}
	},
	"MASTERIES_AFTER": (node, params, source, list) => {
		const { listEntry } = node;
		const times = countSlug(list, source.slug)
		const options = getOptions(listEntry.options, [source.slug]);
		options.forEach(o => {
			if (!o.selected && !o.masterized) {
				o.masterized = true;
				o.cost *= Math.pow(2, times);
			}
		});
	},
	"MASTERIES_PRE": (node, params, source, list) => {
		const { listEntry } = node;
		const times = countSlug(list, source.slug) - 1;
		const options = getOptions(listEntry.options, [source.slug]);
		options.forEach(o => {
			if (o.selected && !o.masterized) {
				o.masterized = true;
				o.cost *= Math.pow(2, times);
			}
		});
	},
	"ECCENTRIC": (node, params, source, list, faction) => {
		const { listEntry } = node;
		const { entrySlugs } =
			faction.entryGroups.find(g => g.slug === "regiments");

		listEntry.warband.restricted = entrySlugs.map(s => faction.entries[s]);
	},
	"KNIGHTLY_ENTOURAGE": (node) => {
		const { listEntry } = node;
		listEntry.warband.mainstay.push({ name: "Mounted Squires", slug: "mounted-squires" });
	},
	"LEGACIES_OF_THE_ARK": (node, params, source, list) => {
		const { listEntry, linkedNodes } = node;
		if (listEntry.isWarlord) {
			const selector = listEntry.entrySelectors.find(s => s.slug === "legacies-of-the-ark-selector");
			if (selector) {
				selector.active = true;

				selector.targets = [];
				list.children.forEach(w => {
					const character = w.children.find(c => c.type === "character");
					if (character && ["lineage-highborne", "mimetic-assassin"].includes(character.listEntry.slug)) {
						selector.targets.push(character);
					}
				})

				if (linkedNodes["legacies-of-the-ark-selector"]) {
					const path = linkedNodes["legacies-of-the-ark-selector"][0];
					const target = getNode(list, path);

					if (target) {
						const mutations = target.listEntry.options.groups.find(g => g.slug === "mutations");
						if (mutations) {
							mutations.maxOptions = 3;
							mutations.orphans.forEach(o => o.cost = 0);
						}
					}
				}
			}
		}
	},
	"RAPID_DEPLOYMENT": (node, params, source, list) => {
		if (node.listEntry.isWarlord) {
			list.tagGroups = [
				{
					name: "Army Rules",
					slug: "army-rules",
					tags: [
						{
							name: "Rapid Deployment",
							slug: "rapid-deployment",
							query: {
								label: "Rapid Deployment",
								request: {
									type: "directives",
									slug: "rapid-deployment"
								}
							}
						}
					]
				}
			]
		}
	},
	"MARCH_OF_THE_FAITHFUL": (node, params, source, list) => {
		if (node.listEntry.isWarlord) {
			list.tagGroups = [
				{
					name: "Army Rules",
					slug: "army-rules",
					tags: [
						{
							name: "March of the Faithful",
							slug: "march-of-the-faithful",
							query: {
								label: "March of the Faithful",
								request: {
									type: "directives",
									slug: "march-of-the-faithful"
								}
							}
						}
					]
				}
			]

			const {children: warbands} = list;
			const regiments = warbands.reduce((prev, cur) => [...prev, ...cur.children.filter(c => c.type !== "character")], []);
			regiments.forEach(r => {
				const tags = r.listEntry.tagGroups?.find(g => g.slug === "battlefield-role")?.tags?.map(t => t.slug) || [];
				if (tags.includes("theist") && tags.includes("veteran")) {
					const sr = r.listEntry.tagGroups.find(g => g.slug === "special-rules");
					if (sr) {
						const divine = sr?.tags?.find(t => t.slug === "divine-purpose");
						if (!divine) {
							sr.tags.push({
								name: "Divine Purpose",
								slug: "divine-purpose",
								query: {
									label: "Divine Purpose",
									request: {
										type: "directives",
										slug: "divine-purpose"
									}
								}
							})
						}
					} else {
						r.listEntry.tagGroups.push({
							name: "Special Rules",
							slug: "special-rules",
							tags: [
								{
									name: "Divine Purpose",
									slug: "divine-purpose",
									query: {
										label: "Divine Purpose",
										request: {
											type: "directives",
											slug: "divine-purpose"
										}
									}
								}
							]
						})
					}
				}
			});
		}
	},
	"FAMILY_TRADITIONS": (node, params, source, list) => {
		if (node.listEntry.isWarlord) {
			list.tagGroups = [
				{
					name: "Army Rules",
					slug: "army-rules",
					tags: [
						{
							name: "Family Traditions",
							slug: "family-traditions",
							query: {
								label: "Family Traditions",
								request: {
									type: "directives",
									slug: "family-traditions"
								}
							}
						}
					]
				}
			]

			const warbandRegiments = node.parent.children.filter(c => c.type !== "character")
			warbandRegiments.forEach(r => {
				const tags = r.listEntry.tagGroups?.find(g => g.slug === "battlefield-role")?.tags?.map(t => t.slug) || [];
				if (tags.includes("feudal")) {
					const br = r.listEntry.tagGroups.find(g => g.slug === "battlefield-role");
					if (br) {
						const veteran = br?.tags?.find(t => t.slug === "veteran");
						if (!veteran) {
							br.tags.push({
								name: "Veteran",
								slug: "veteran"
							})
						}
					}
				}
			});

			const roles = {
				"theist": {
					name: "Theist",
					slug: "theist",
				},
				"feudal": {
					name: "Feudal",
					slug: "feudal",
				},
				"orders": {
					name: "The Orders",
					slug: "the-orders",
				},
				"imperial": {
					name: "Imperial",
					slug: "imperial",
				},
			};
			let role = null;

			const {children: warbands} = list;
			const characters = warbands.reduce((prev, cur) => [...prev, ...cur.children.filter(c => c.type === "character")], []);
			characters.forEach(c => {
				if (c.selections.includes("dynastic-ally")) {
					const tags = c.listEntry.tagGroups?.find(g => g.slug === "battlefield-role")?.tags?.map(t => t.slug) || [];
					role ??= tags.includes("theist") ? "theist" : null;
					role ??= tags.includes("feudal") ? "feudal" : null;
					role ??= tags.includes("the-orders") ? "orders" : null;
					role ??= tags.includes("imperial") ? "imperial" : null;
				}
			});

			const nodes = warbands.reduce((prev, cur) => [...prev, ...cur.children], []);
			nodes.forEach(r => {
				const br = r.listEntry.tagGroups.find(g => g.slug === "battlefield-role");
				const tags = br?.tags?.map(t => t.slug) || [];
				if (tags.includes("feudal")) {
					if (role && !tags.find(t => t === role)) {
						br.tags.push({
							name: roles[role].name,
							slug: roles[role].slug
						})
					}
				}
			});
		}
	},
	"MARTIAL_PROWESS": (node, params, source, list) => {
		if (node.listEntry.isWarlord) {
			list.tagGroups = [
				{
					name: "Army Rules",
					slug: "army-rules",
					tags: [
						{
							name: "Martial Prowess",
							slug: "martial-prowess",
							query: {
								label: "Martial Prowess",
								request: {
									type: "directives",
									slug: "martial-prowess"
								}
							}
						}
					]
				}
			]

			const {children: warbands} = list;
			const characters = warbands.reduce((prev, cur) => [...prev, ...cur.children.filter(c => c.type === "character")], []);
			characters.forEach(c => {
				const tags = c.listEntry.tagGroups?.find(g => g.slug === "battlefield-role")?.tags?.map(t => t.slug) || [];
				if (tags.includes("the-orders")) {
					const sr = c.listEntry.tagGroups.find(g => g.slug === "special-rules");
					if (sr) {
						const flurry = sr?.tags?.find(t => t.slug === "flurry");
						if (!flurry) {
							sr.tags.push({
								name: "Flurry",
								slug: "flurry",
								query: {
									label: "Flurry",
									request: {
										type: "directives",
										slug: "flurry"
									}
								}
							})
						}
					} else {
						c.listEntry.tagGroups.push({
							name: "Special Rules",
							slug: "special-rules",
							tags: [
								{
									name: "Flurry",
									slug: "flurry",
									query: {
										label: "Flurry",
										request: {
											type: "directives",
											slug: "flurry"
										}
									}
								}
							]
						})
					}
				}
			});
		}
	},
	"ARDENT_CREED": (node, params, source, list) => {
		if (node.listEntry.isWarlord) {
			list.tagGroups = [
				{
					name: "Army Rules",
					slug: "army-rules",
					tags: [
						{
							name: "The Ardent Creed",
							slug: "the-ardent-creed",
							query: {
								label: "The Ardent Creed",
								request: {
									type: "directives",
									slug: "the-ardent-creed"
								}
							}
						}
					]
				}
			]
		}
	},
	"TEMPERED_CREED": (node, params, source, list) => {
		if (node.listEntry.isWarlord) {
			list.tagGroups = [
				{
					name: "Army Rules",
					slug: "army-rules",
					tags: [
						{
							name: "The Tempered Creed",
							slug: "the-tempered-creed",
							query: {
								label: "The Tempered Creed",
								request: {
									type: "directives",
									slug: "the-tempered-creed"
								}
							}
						}
					]
				}
			]

			list.children.forEach(w => {
				const slugs = ["tempered-steelshaper", "tempered-sorcerer"];
				const targets = w.children.filter(c => c.type === "character" && slugs.includes(c.listEntry.slug));
				targets.forEach(t => {
					const group = t.listEntry.tagGroups.find(g => g.slug === "special-rules")
					group.tags.push({
						name: "Elemental Potency",
						slug: "elemental-potency",
						query: {
							label: "Elemental Potency",
							request: {
								type: "directives",
								slug: "elemental-potency"
							}
						}
					})
				})
			})
		}
	},
	"WARLORD": (node, params, source, list, faction) => {
		// TODO: Names should never be used in rules, effects, etc. only slugs.
		// There should be a way to derive the name from the faction data.
		// With tags this seems easy because they already have unique slugs, so
		// pull them out in a tags object just like entries and then only use slugs
		// in the entry data.
		if (node.listEntry.isWarlord) {
			const { tagGroups = [] } = node.listEntry;
			if (faction.slug === "the-hundred-kingdoms") {
				const { children: warbands } = list;
				warbands.forEach(w => {
					const character = w.children.find(c => c.type === "character" && c.entrySlug !== node.entrySlug);
					if (character) {
						const ally = character.listEntry.options?.orphans?.find(o => o.slug === "dynastic-ally");
						if (ally) ally.active = true;
					}
				});
			} else if (faction.slug === "the-wadrhun") {
				let slug = null;
				let name = null;
				let entries = [];

				if (node.entrySlug === "scion-of-conquest") {
					slug = "cult-of-conquest";
					name = "Cult of Conquest";
				}
				else if (getTag(tagGroups, "war")) {
					slug = "cult-of-war";
					name = "Cult of War";
					entries.push(
						{
							"name": "Chosen of War",
							"slug": "chosen-of-war"
						},
					);
				} else if (getTag(tagGroups, "death")) {
					slug = "cult-of-death";
					name = "Cult of Death";
					entries.push(
						{
							"name": "Chosen of Death",
							"slug": "chosen-of-death"
						},
					);
				} else if (getTag(tagGroups, "famine")) {
					slug = "cult-of-famine";
					name = "Cult of Famine";
					entries.push(
						{
							"name": "Chosen of Famine",
							"slug": "chosen-of-famine"
						},
					);
				} else if (getTag(tagGroups, "conquest")) {
					slug = "cult-of-conquest";
					name = "Cult of Conquest";
					entries.push(
						{
							"name": "Chosen of Conquest",
							"slug": "chosen-of-conquest"
						},
					);
				}

				if (slug) {
					const nodes = [];

					if (node.entrySlug !== "scion-of-conquest") {
						nodes.push(node);

						const { listEntry } = node;
						listEntry.warband.mainstay = listEntry.warband.mainstay.filter(e => !entries.some(s => e.slug === s.slug));
						listEntry.warband.mainstay.push(...entries);

						const entrySlugs = entries.map(e => e.slug);
						const attached = node.parent.children.filter(c => entrySlugs.includes(c.entrySlug))
						attached.forEach(a => a.type = "mainstay");
					}

					const links = Object.keys(node.linkedNodes || {});
					links.forEach(l => {
						const linkedNode = getNode(list, node.linkedNodes[l][0])
						if (linkedNode) nodes.push(linkedNode);
					});

					nodes.forEach(n => {
						const { tagGroups = [] } = n.listEntry;
						const existingGroup = tagGroups.find(g => g.slug === "battlefield-role");
						if (!existingGroup) {
							n.listEntry.tagGroups.push({
								name: "Battlefield Role",
								slug: "battlefield-role",
								tags: [
									{
										name,
										slug
									}
								]
							});
						} else {
							existingGroup.tags.push({
								name,
								slug
							});
						}
					});
				}
			} else if (["biomancer", "high-clone-executor"].includes(node.listEntry.slug)) {
				const { children: warbands } = list;
				const biomancers = [];
				warbands.forEach(w => {
					biomancers.push(...w.children.filter(c => c.entrySlug === "biomancer"));
				});

				biomancers.forEach(b => {
					const biomancies = b.listEntry.tagGroups.find(g => g.slug === "biomancies");
					if (biomancies) {
						biomancies.tags.push(
							{
								name: "Subdermal Keratin Induction",
								slug: "subdermal-keratin-induction",
								query: {
									label: "Subdermal Keratin Induction",
									request: {
										type: "directives",
										slug: "subdermal-keratin-induction"
									}
								}
							},
							{
								name: "Adrenal Surge",
								slug: "adrenal-surge",
								query: {
									label: "Adrenal Surge",
									request: {
										type: "directives",
										slug: "adrenal-surge"
									}
								}
							}
						);
					}
				});
			} else if (node.listEntry.slug === "lineage-prideborne") {
				const specialRules = node.listEntry.tagGroups.find(g => g.slug === "special-rules");
				const hunterKiller = specialRules.tags.find(t => t.slug === "hunter-killer-teams");
				hunterKiller.query = {
					label: "Hunter-Killer Teams",
					request: {
						slug: "hunter-killer-teams-alt",
						type: "directives"
					}
				}
			}
		}
	},
	"HELLBRINGER_SORCERER": (node, params, source, list) => {
		const { listEntry, linkedNodes } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "hellbringer-sorcerer-mount");
		if (selector) {
			const drakes = node.parent.children.filter(c => c.listEntry.slug === "hellbringer-drake");
			selector.targets = drakes;
			selector.active = true;

			// if (linkedNodes["hellbringer-sorcerer-mount"]) {
			// 	const path = linkedNodes["hellbringer-sorcerer-mount"][0];
			// 	const target = getNode(list, path);
			// 	if (target) {
			// 		const special = target.listEntry.tagGroups.find(g => g.slug === "special-rules");
			// 		special.tags.push({
			// 			name: "You and What Army?",
			// 			slug: "you-and-what-army",
			// 			query: {
			// 				label: "You and What Army?",
			// 				request: {
			// 					type: "directives",
			// 					slug: "you-and-what-army"
			// 				}
			// 			}
			// 		});
			// 	}
			// }
		}
	},
	"BERGONT_RAEGH": (node, params, source, list) => {
		const { listEntry, linkedNodes } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "infernal-rider-mount");
		if (selector) {
			const drakes = node.parent.children.filter(c => c.listEntry.slug === "ironclad-drake");
			selector.targets = drakes;
			selector.active = true;

			// if (linkedNodes["infernal-rider-mount"]) {
			// 	const path = linkedNodes["infernal-rider-mount"][0];
			// 	const target = getNode(list, path);
			// 	if (target) {
			// 		const special = target.listEntry.tagGroups.find(g => g.slug === "special-rules");
			// 		special.tags.push({
			// 			name: "You and What Army?",
			// 			slug: "you-and-what-army",
			// 			query: {
			// 				label: "You and What Army?",
			// 				request: {
			// 					type: "directives",
			// 					slug: "you-and-what-army"
			// 				}
			// 			}
			// 		});
			// 	}
			// }
		}
		if (!listEntry.isWarlord) {
			node.alerts.push({ message: "A Bergont Raegh must be selected as Warlord." });
		}
	},
	"THUNDEROUS_ASSAULT": (node, params, source, list) => {
		const { listEntry } = node;
		if (listEntry.isWarlord) {
			const { children: warbands } = list;
			const regiments = [];

			warbands.forEach(w => {
				regiments.push(...w.children.filter(c => (c.type !== "character" && getTag(c.listEntry?.tagGroups || [], "the-speakers")) || c.entrySlug === "winglord-predator"));
			});

			regiments.forEach(r => {
				r.listEntry.tagGroups ||= [];
				const sr = r.listEntry.tagGroups.find(g => g.slug === "special-rules");
				if (sr) {
					const shock = sr?.tags?.find(t => t.slug === "shock");
					if (!shock) {
						sr.tags.push({
							name: "Shock",
							slug: "shock",
							query: {
								label: "Shock",
								request: {
									type: "directives",
									slug: "shock"
								}
							}
						})
					}
				} else {
					r.listEntry.tagGroups.push({
						name: "Special Rules",
						slug: "special-rules",
						tags: [
							{
								name: "Shock",
								slug: "shock",
								query: {
									label: "Shock",
									request: {
										type: "directives",
										slug: "shock"
									}
								}
							}
						]
					})
				}
			})
		}
	},
	"APEX_MASTER": (node, params, source, list) => {
		const { listEntry, linkedNodes } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "apex-master-mount");
		if (selector) {
			const predators = node.parent.children.filter(c => c.listEntry.slug === "apex-predator");
			selector.targets = predators;
			selector.active = true;

			if (linkedNodes["apex-master-mount"]) {
				const path = linkedNodes["apex-master-mount"][0];
				const target = getNode(list, path);
				if (target) {
					// target.listEntry.tagGroups.push({
					// 	name: "Draw Events",
					// 	slug: "draw-event",
					// 	tags: [
					// 		{
					// 			name: "Bastion",
					// 			slug: "bastion",
					// 			variable: 1,
					// 			query: {
					// 				label: "Bastion X",
					// 				request: {
					// 					type: "directives",
					// 					slug: "bastion"
					// 				}
					// 			}
					// 		}
					// 	]
					// });

					// const special = target.listEntry.tagGroups.find(g => g.slug === "special-rules");
					// special.tags.push({
					// 	name: "You and What Army?",
					// 	slug: "you-and-what-army",
					// 	query: {
					// 		label: "You and What Army?",
					// 		request: {
					// 			type: "directives",
					// 			slug: "you-and-what-army"
					// 		}
					// 	}
					// });
				}
			}
		}
	},
	"LORD_OF_ALL_THEY_SEE": (node, params, source, list) => {
		const { listEntry, linkedNodes } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "lord-of-all-they-see-mount");
		if (selector) {
			const mahuts = node.parent.children.filter(c => c.listEntry.slug === "mahut");
			selector.targets = mahuts;
			selector.active = true;
		}
	},
	"FERRIC_THRONE": (node, params, source, list) => {
		const { listEntry, linkedNodes } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "ferric-throne-mount");
		if (selector) {
			const stoneforged = node.parent.children.filter(c => c.listEntry.slug === "stoneforged");
			selector.targets = stoneforged;
			selector.active = true;
		}
	},
	"TONTORR_RIDER": (node, params, source, list) => {
		const { listEntry } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "tontorr-rider-mount");
		if (selector) {
			const tontorrs = node.parent.children.filter(c => c.listEntry.slug === "tontorr");
			selector.targets = tontorrs;
			selector.active = true;
		}
	},
	"DRUM_BEAST_RIDER": (node, params, source, list) => {
		const { listEntry } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "drum-beast-rider-mount");
		if (selector) {
			const beasts = node.parent.children.filter(c => c.listEntry.slug === "drum-beast");
			selector.targets = beasts;
			selector.active = true;
		}
	},
	"SANCTIFIED_LABARON": (node, params, source, list) => {
		const { listEntry, linkedNodes } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "sanctified-labaron");
		if (selector) {
			const restricted = node.parent.children.filter(c => c.type === "restricted");
			selector.targets = restricted;
			selector.active = true;

			if (linkedNodes["sanctified-labaron"]) {
				const path = linkedNodes["sanctified-labaron"][0];
				const target = getNode(list, path);
				if (target) {
					target.type = "mainstay"
				}
			}
		}
	},
	"ENABLE_PROFILE": (node, params) => {
		const { listEntry } = node;
		const profile = listEntry.profiles?.find(p => p.profileName === params.profileName);
		if (profile) profile.active = params.enabled;
	},
	"GODFLESH": (node, params, source, list) => {
		const { listEntry } = node;
		if (listEntry.isWarlord) {
			const { children: warbands } = list;
			const regiments = [];

			warbands.forEach(w => {
				regiments.push(...w.children.filter(c => c.entrySlug === "clockwork-hoplites"));
			});

			regiments.forEach(r => r.listEntry.gameClass = "Medium");
		}
	},
	"MODIFY_WARBAND": (node, params) => {
		const { listEntry } = node;
		const { entries, type } = params;
		listEntry.warband.restricted = listEntry.warband.restricted.filter(e => !entries.some(s => e.slug === s.slug));
		listEntry.warband.mainstay = listEntry.warband.mainstay.filter(e => !entries.some(s => e.slug === s.slug));

		if (type === "mainstay") {
			listEntry.warband.mainstay.push(...entries);
		} else if (type === "restricted") {
			listEntry.warband.restricted.push(...entries);
		}

		const entrySlugs = entries.map(e => e.slug);
		const attached = node.parent.children.filter(c => entrySlugs.includes(c.entrySlug))
		attached.forEach(a => a.type = type);
	},
	"ADD_TEXT": (node, params) => {
		const { listEntry } = node;

		listEntry.texts ??= [];

		listEntry.texts.push({
			tag: "",
			content: params.text,
		})
	},
	"ADVANCED_INFANTRY": (node, params) => {
		const roles = node.listEntry.tagGroups?.find(g => g.slug === "battlefield-role")?.tags?.map(t => t.slug) || [];
		if (roles.includes("veteran")) {
			const sr = node.listEntry.tagGroups.find(g => g.slug === "special-rules");
			if (sr) {
				const support = sr?.tags?.find(t => t.slug === "support");
				if (!support) {
					sr.tags.push({
						name: "Support",
						slug: "support",
						query: {
							label: "Support X",
							request: {
								type: "directives",
								slug: "support"
							}
						},
						variable: 2
					})
				} else {
					support.variable += 1;
				}
			} else {
				node.listEntry.tagGroups.push({
					name: "Special Rules",
					slug: "special-rules",
					tags: [
						{
							name: "Support",
							slug: "support",
							query: {
								label: "Support X",
								request: {
									type: "directives",
									slug: "support"
								}
							},
							variable: 2
						}
					]
				})
			}
		}
	},
	"ADVANCED_CAVALRY": (node, params) => {
		const roles = node.listEntry.tagGroups?.find(g => g.slug === "battlefield-role")?.tags?.map(t => t.slug) || [];
		if (roles.includes("veteran")) {
			node.listEntry.statline["C"] += 1;
		}
	},
}

function countSlug(node, slug) {
	let local = node.selections.filter(e => e === slug).length;
	node.children.forEach(c => {
		local += countSlug(c, slug);
	})
	return local;
}


export default TLAOK_EFFECTS