import {
	Paper,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import FancyTypography from "../../ui/FancyTypography";
import Warband from "./options/Warband";
import { Box } from "@mui/system";
import TagGroup from "../bits/TagGroup";
import useGameSettings from "../../hooks/use-game-settings";
import QueriesStack from "../query/QueriesStack";


function EntryPanelContent({ entry, activeProfileIndex = null, onProfileChange }) {
	const {
		options = {},
		warband,
		texts,
		profiles,
		minSize,
		maxSize,
		sizeStepCost,
	} = entry;
	const { groups: optionGroups = [], orphans = [] } = options;
	const { getGameSettings, getComponent } = useGameSettings();
	const { entryUnit } = getGameSettings();
	const activeProfile = activeProfileIndex === null ? null : profiles[activeProfileIndex];
	const tagGroups = activeProfile?.tagGroups || entry.tagGroups;
	const hasTags = tagGroups?.length > 0;
	const canIncreaseSize = maxSize > minSize;
	const hasOptionGroups = optionGroups.filter(g => g.active != false).length > 0;
	const hasOrphanOptions = orphans.filter(o => o.active != false).length > 0;
	const hasOptions = canIncreaseSize || hasOptionGroups || hasOrphanOptions;

	function handleProfileNameChange(event, name) {
		if (name) {
			const index = profiles.findIndex(p => p.profileName === name);
			onProfileChange(index);
		}
	}

	const tagsSection = !hasTags ? null : (
		<Stack spacing={1}>
			{tagGroups.map((g) => (
				<TagGroup key={g.slug} group={g} />
			))}
		</Stack>
	);

	const textsSection = !texts
		? null
		: texts.map((t, i) => (
			<Box key={i} component={Paper} variant="outlined" p={1}>
				<Typography dangerouslySetInnerHTML={{ __html: t.content }} />
				{t.queries && <QueriesStack queries={t.queries} mt={1} />}
			</Box>
		));

	// TODO: Add orphans
	const optionsSection = !hasOptions ? null : (
		<Stack spacing={1}>
			{canIncreaseSize && (
				<Box component={Paper} variant="outlined" p={1}>
					<Stack direction="row" justifyContent="space-between">
						<Typography>Additional {entryUnit}</Typography>
						<Typography>{sizeStepCost || "Free"}</Typography>
					</Stack>
				</Box>
			)}
			{hasOrphanOptions && (
				<Box component={Paper} variant="outlined" p={1}>
				{orphans
					.filter(o => o.active !== false)
					.map(o => (
						<Stack direction="row" justifyContent="space-between">
							<Typography>{o.name}</Typography>
							<Typography>{o.cost || "Free"}</Typography>
						</Stack>
					))
				}
				</Box>
			)}
			{optionGroups
				.filter((g) => g.active !== false)
				.map((g) => {
					const OptionsComponent = getComponent("entry-options", g.slug);
					return <OptionsComponent key={g.slug} group={g} />;
				})
			}
		</Stack>
	);

	const warbandSection = !warband ? null : (
		<>
			<FancyTypography variant="h5">Warband</FancyTypography>
			<Warband warband={warband} />
		</>
	);

	return (
		<>
			{activeProfile && (
				<ToggleButtonGroup
					exclusive
					value={activeProfile.profileName}
					size="small"
					fullWidth
					onChange={handleProfileNameChange}
					sx={{
						mb: 2,
						"& .MuiToggleButton-root": {
							fontSize: "16px",
							p: 0.5,
						},
					}}
				>
					{profiles.map((p) => (
						<ToggleButton key={p.profileName} value={p.profileName}>
							{p.profileName}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			)}
			<Stack spacing={3}>
				{tagsSection}
				{textsSection}
				{hasOptions && <FancyTypography variant="h5">Options</FancyTypography>}
				{optionsSection}
				{warbandSection}
			</Stack>
		</>
	);
}


export default EntryPanelContent;