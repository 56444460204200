export const QR_MEDIA = {
	"tlaok": {
		"MEDnt7gc": {
			"type": "link",
			"url": "https://www.para-bellum.com/wp-content/uploads/2025/01/TLAOK-TOURNAMENT-SCENARIO-PACK-JANUARY-UPDATES.pdf"
		},
	},
	"fb": {
		"MED01uvr": {
			"type": "link",
			"url": "https://www.para-bellum.com/wp-content/uploads/2024/07/FB-Competitive-Scenario-Pack-A-Realm-Reborn-Update.pdf"
		},
	},
};
