function hasTags(entry, tags) {
	entry.tagGroups?.forEach(g => {
		g.tags.forEach(t => {
			tags = tags.filter(s => s !== t.slug);
		});
	});

	entry.profiles?.forEach(p => {
		p.tagGroups?.forEach(g => {
			g.tags.forEach(t => {
				tags = tags.filter(s => s !== t.slug);
			});
		});
	});

	return tags.length <= 0;
}

const TLAOK_TARGETS = {
	"LIST": (list) => {
		return [list];
	},
	"REGIMENTS": (list, origin, params) => {
		let regiments = list.children.filter(c => c.type === "regiment");

		const slugFilter = params?.filters?.slugs || [];
		if (slugFilter.length > 0) {
			regiments = regiments.filter(r => slugFilter.includes(r.entrySlug));
		}
		const typeFilter = params?.filters?.types || [];
		if (typeFilter.length > 0) {
			regiments = regiments.filter(r => typeFilter.includes(r.type));
		}
		const tagFilter = params?.filters?.tags || [];
		if (tagFilter.length > 0) {
			regiments = regiments.filter(r => hasTags(r.listEntry, tagFilter));
		}

		return regiments;
	},
	"CHARACTERS": (list) => {
		const { children = [] } = list;
		return children.filter(c => c.type === "character");
	},
	"PARENT": (list, origin) => {
		return [origin.parent];
	},
	"ALL_OTHER_CHARACTERS": (list, origin) => {
		const { children: warbands } = list;
		const characters = [];
		warbands.forEach(w => {
			const character = w.children.find(c => c.type === "character" && c.id !== origin.id);
			if (character) characters.push(character);
		});
		return characters;
	},
	"SELF": (list, origin) => {
		return [origin];
	},
	"REGIMENT": (list, origin) => {
		return [origin, ...origin.children];
	},
	"PARENT_REGIMENT": (list, origin) => {
		const parent = origin.parent;
		return [parent, ...parent.children];
	},
	"CHILDREN": (list, origin) => {
		return [...origin.children];
	},
}


export default TLAOK_TARGETS;