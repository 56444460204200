function getOptions(group, slugs) {
	const { orphans = [], groups = [] } = group;
	const options = [];

	options.push(...orphans.filter(o => slugs.includes(o.slug)));
	groups.forEach(g => {
		options.push(...getOptions(g, slugs))
	});

	return options;
}

function getOptionGroups(groups, slugs) {
	const results = [];

	groups.forEach(g => {
		if (slugs.includes(g.slug)) results.push(g);
		if (g.groups) {
			results.push(...getOptionGroups(g.groups, slugs));
		}
	});

	return results;
}

function getTag(groups, slug) {
	for (const g of groups) {
		const tag = g.tags?.find(t => t.slug === slug);
		if (tag) return tag;
	}
	return null;
}


function setGroupEnabled(group, enabled) {
	const { orphans = [] } = group;
	group.active = enabled;
	orphans.forEach(o => o.active = enabled);
}

function operate(target, params, listEntry) {
	const { operation, value } = params;

	if (operation === "add") {
		return target + value;
	} else if (operation === "add-per-unit") {
		return target + value * listEntry.size;
	} else if (operation === "subtract") {
		return target - value;
	}
}

const FB_EFFECTS = {
	"MODIFY_SIZE": (node, params) => {
		node.listEntry.size = operate(node.listEntry.size, params);
	},
	"ENABLE_OPTIONS": (node, params) => {
		const { slugs, enabled } = params;
		if (node.listEntry.options) {
			const options = getOptions(node.listEntry.options, slugs);
			options.forEach(o => o.active = enabled);
		}
	},
	"ENABLE_OPTION_GROUPS": (node, params) => {
		const { slugs, enabled } = params;
		const allGroups = node?.listEntry?.options?.groups || node?.options?.groups;
		if (allGroups) {
			const groups = getOptionGroups(allGroups, slugs);
			groups.forEach(g => setGroupEnabled(g, enabled));
		}
	},
	"VETERANS_PERKS": (node, params, source, list) => {
		const { listEntry, selections } = node;

		if (list.selections.includes("veterans")) {
			const mustHave = ["veterans", "leader", "standard-bearer"];
			const notSelected = mustHave.filter(s => !selections.includes(s));

			if (notSelected.length === 0) {
				const group = listEntry.options.groups.find(g => g.slug === "veterans-perks");
				if (group) setGroupEnabled(group, true);
			}
		}
	},
	"MODIFY_OPTION_GROUP": (node, params) => {
		const { groups = [] } = node.listEntry.options;
		const { slug, minOptions, maxOptions } = params;

		const group = groups.find(g => g.slug === slug)
		if (group) {
			if (minOptions) {
				group.minOptions = operate(group.minOptions, minOptions);
			}
			if (maxOptions) {
				group.maxOptions = operate(group.maxOptions, maxOptions);
			}
		}
	},
	"MODIFY_OPTION_COST": (node, params) => {
		const { groupSlugs = [], optionSlugs = [], action } = params;
		const { listEntry } = node;

		function modify(option) {
			if (action === "DOUBLE") option.cost *= 2;
			if (action === "FREE") option.cost = 0;
		}

		if (groupSlugs.length) {
			listEntry.options.groups.forEach(g => {
				const { orphans = [] } = g;
				orphans.forEach(o => modify(o));
			})
		} else if (optionSlugs.length) {
			listEntry.options.orphans.forEach(o => o.cost = operate(o.cost, params, listEntry));
		}
	},
	"REMOVE_TAGS": (node, params) => {
		const { listEntry } = node;
		const { tagGroup, tags = [], profileName } = params;

		if (!tagGroup || tags.length <= 0) return;
		if (!listEntry.profiles && profileName) return;

		let profileIndex = null;
		if (listEntry.profiles) {
			if (profileName) {
				profileIndex = listEntry.profiles.findIndex(p => p.profileName === profileName);
				if (profileIndex < 0) {
					return;
				}
			} else {
				profileIndex = 0;
			}
		}

		const tagGroups = listEntry?.tagGroups || listEntry?.profiles?.[profileIndex]?.tagGroups || node.tagGroups;

		const group = tagGroups?.find(g => g.slug === tagGroup);
		if (group) {
			group.tags = group.tags.filter(t => !tags.includes(t.slug));
		}
	},
	"ADD_TAGS": (node, params) => {
		const { listEntry } = node;
		const { tagGroup, tags = [], profileName } = params;

		if (!tagGroup || tags.length <= 0) return;

		// findGroup
		// if not, create it and push tags
		// if exists, look for tags

		if (!listEntry.profiles && profileName) return;

		if (listEntry.profiles) {
			let start = 0;
			let end = listEntry.profiles.length;

			if (profileName) {
				const profileIndex = listEntry.profiles.findIndex(p => p.profileName === profileName);
				if (profileIndex < 0) {
					return;
				} else {
					start = profileIndex;
					end = profileIndex + 1;
				}
			}

			for (let i = start; i < end; ++i) {
				const tagGroups = listEntry.profiles[i]?.tagGroups || [];
				const group = tagGroups?.find(g => g.slug === tagGroup.slug);
				if (group) {
					tags.forEach(t => {
						const existingTag = group.tags.find(e => e.slug === t.slug)
						if (existingTag && existingTag.variable && t.variable) {
							existingTag.variable = Math.max(existingTag.variable, t.variable);
						} else if (!existingTag) {
							if (t.slug === "wizard" && group.tags.find(e => e.slug === "priest")) return;
							group.tags.push(t);
						}
					})
				} else {
					const newGroup = { ...tagGroup };
					newGroup.tags = tags;
					if (listEntry) {
						listEntry.tagGroups ||= [];
						listEntry.tagGroups.push(newGroup);
					} else {
						node.tagGroups = [newGroup];
					}
				}
			}
		}

		const tagGroups = listEntry?.tagGroups || node.tagGroups;

		const group = tagGroups?.find(g => g.slug === tagGroup.slug);
		if (group) {
			tags.forEach(t => {
				const existingTag = group.tags.find(e => e.slug === t.slug)
				if (existingTag && existingTag.variable && t.variable) {
					existingTag.variable = Math.max(existingTag.variable, t.variable);
				} else if (!existingTag) {
					if (t.slug === "wizard" && group.tags.find(e => e.slug === "priest")) return;
					group.tags.push(t);
				}
			})
		} else {
			const newGroup = { ...tagGroup };
			newGroup.tags = tags;
			if (listEntry) {
				listEntry.tagGroups ||= [];
				listEntry.tagGroups.push(newGroup);
			} else {
				node.tagGroups = [newGroup];
			}
		}
	},
	"MODIFY_TAG": (node, params) => {
		const { tagGroups = [] } = node.listEntry;
		const { name, slug, variable, range, tags, query, tagGroup } = params;

		const tag = getTag(tagGroups, slug);
		if (tag) {
			if (variable) {
				tag.variable = operate(tag.variable, variable);
			}
			if (range) {
				tag.range = operate(tag.range, range);
			}
			if (tags) {
				tag.tags ||= [];
				tag.tags.push(...tags);
			}
			if (query) {
				tag.query = query;
			}
		} else if (variable?.operation === "add") {
			const group = tagGroups.find(g => g.slug === tagGroup?.slug);
			if (group) {
				group.tags.push({
					name,
					slug,
					variable: variable.value,
					query,
					range,
					tags
				})
			} else {
				tagGroups.push({
					name: tagGroup.name,
					slug: tagGroup.slug,
					tags: [
						{
							name,
							slug,
							variable: variable.value,
							query,
							range,
							tags
						}
					]
				})
			}
		}
	},
	"MODIFY_TYPE": (node, params) => {
		const { listEntry } = node;
		const { newType } = params;

		if (listEntry) {
			listEntry.type = newType;
		}
	},
	"MODIFY_STAT": (node, params) => {
		const { listEntry } = node;
		const { key, operation, variable, min, max } = params;

		function limit(a) {
			if (a < min) return min;
			if (a > max) return max;
			return a;
		}

		const statlines = [];
		if (listEntry.profiles) {
			listEntry.profiles.forEach(profile => {
				statlines.push(profile.statline);
			});
		} else {
			statlines.push(listEntry.statline);
		}

		statlines.forEach(statline => {
			if (statline[key] === "-") return;

			if (isNaN(statline[key])) statline[key] = 0;

			if (operation === "add") {
				statline[key] = limit(statline[key] + variable);
			} else if (operation === "subtract") {
				statline[key] = limit(statline[key] - variable);
			} else if (operation === "multiply") {
				statline[key] = limit(statline[key] * variable);
			} else if (operation === "divide") {
				statline[key] = limit(statline[key] / variable);
			}
		});
	},
	"MASTERIES_AFTER": (node, params, source, list) => {
		const { listEntry } = node;
		const times = countSlug(list, source.slug)
		const options = getOptions(listEntry.options, [source.slug]);
		options.forEach(o => {
			if (!o.selected && !o.masterized) {
				o.masterized = true;
				o.cost *= Math.pow(2, times);
			}
		});
	},
	"MASTERIES_PRE": (node, params, source, list) => {
		const { listEntry } = node;
		const times = countSlug(list, source.slug) - 1;
		const options = getOptions(listEntry.options, [source.slug]);
		options.forEach(o => {
			if (o.selected && !o.masterized) {
				o.masterized = true;
				o.cost *= Math.pow(2, times);
			}
		});
	},
	"ECCENTRIC": (node, params, source, list, faction) => {
		const { listEntry } = node;
		const { entrySlugs } =
			faction.entryGroups.find(g => g.slug === "regiments");

		listEntry.warband.restricted = entrySlugs.map(s => faction.entries[s]);
	},
	"KNIGHTLY_ENTOURAGE": (node) => {
		const { listEntry } = node;
		listEntry.warband.mainstay.push({ name: "Mounted Squires", slug: "mounted-squires" });
	},
	"LEGACIES_OF_THE_ARK": (node, params, source, list) => {
		const { listEntry } = node;
		if (listEntry.isWarlord) {
			const selector = listEntry.entrySelectors.find(s => s.slug === "legacies-of-the-ark-selector");
			if (selector) {
				selector.active = true;

				selector.targets = [];
				list.children.forEach(w => {
					const character = w.children.find(c => c.type === "character");
					if (character && ["lineage-highborne", "mimetic-assassin"].includes(character.listEntry.slug)) {
						selector.targets.push(character);
					}
				})

				if (selector.selections) {
					const targetId = selector.selections[0];
					list.children.forEach(w => {
						const character = w.children.find(c => c.type === "character" && c.id === targetId);
						if (character) {
							const mutations = character.listEntry.options.groups.find(g => g.slug === "mutations");
							if (mutations) {
								mutations.maxOptions = 3;
								mutations.orphans.forEach(o => o.cost = 0);
							}
						}
					})
				}
			}
		}
	},
	"ARDENT_CREED": (node, params, source, list) => {
		if (node.listEntry.isWarlord) {
			list.tagGroups = [
				{
					name: "Army Rules",
					slug: "army-rules",
					tags: [
						{
							name: "The Ardent Creed",
							slug: "the-ardent-creed",
							query: {
								label: "The Ardent Creed",
								request: {
									type: "directives",
									slug: "the-ardent-creed"
								}
							}
						}
					]
				}
			]

			const regiments = node.parent.children.filter(c => c.type !== "character")
			regiments.forEach(r => r.listEntry.statline["M"] += 1)
		}
	},
	"TEMPERED_CREED": (node, params, source, list) => {
		if (node.listEntry.isWarlord) {
			list.tagGroups = [
				{
					name: "Army Rules",
					slug: "army-rules",
					tags: [
						{
							name: "The Tempered Creed",
							slug: "the-tempered-creed",
							query: {
								label: "The Tempered Creed",
								request: {
									type: "directives",
									slug: "the-tempered-creed"
								}
							}
						}
					]
				}
			]

			list.children.forEach(w => {
				const slugs = ["tempered-steelshaper", "tempered-sorcerer"];
				const targets = w.children.filter(c => c.type === "character" && slugs.includes(c.listEntry.slug));
				targets.forEach(t => {
					const group = t.listEntry.tagGroups.find(g => g.slug === "special-rules")
					group.tags.push({
						name: "Elemental Potency",
						slug: "elemental-potency",
						query: {
							label: "Elemental Potency",
							request: {
								type: "directives",
								slug: "elemental-potency"
							}
						}
					})
				})
			})
		}
	}, "RAEGH_CREED": (node, params, source, list) => {
		const { children: warbands } = list;
		const regiments = [];
		const thanes = [];
		warbands.forEach(w => {
			thanes.push(...w.children.filter(c => c.type !== "character" && c.listEntry.slug === "hold-thanes"));
			regiments.push(...w.children.filter(c => c.type !== "character"));
		});

		if (node.listEntry.isWarlord) {
			const group = node.listEntry.options.groups.find(g => g.slug === "dweghom-creed");
			group.active = true;

			thanes.forEach(r => {
				const group = r.listEntry.tagGroups.find(g => g.slug === "special-rules");
				if (group) {
					group.tags.push({
						name: "Hardened",
						slug: "hardened",
						variable: 1,
						query: {
							label: "Hardened X",
							request: {
								type: "directives",
								slug: "hardened"
							}
						}
					})
				}
			});
		}

		// regiments.forEach(r => {
		// 	const command = r.listEntry.options?.groups?.find(g => g.slug === "command-models");
		// 	if (command) {
		// 		const apprentice = command.orphans.find(g => g.slug === "mnemancer-apprentice");
		// 		if (apprentice) apprentice.active = true;
		// 	}
		// });
	},
	"HELLBRINGER_SORCERER": (node, params, source, list) => {
		const { listEntry } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "hellbringer-sorcerer-mount");
		if (selector) {
			const drakes = node.parent.children.filter(c => c.listEntry.slug === "hellbringer-drake");
			selector.targets = drakes;
			selector.active = true;

			if (selector.selections) {
				const targetId = selector.selections[0];
				const target = node.parent.children.find(c => c.id === targetId);
				if (target) {
					target.listEntry.tagGroups.push({
						name: "Draw Events",
						slug: "draw-event",
						tags: [
							{
								name: "Bastion",
								slug: "bastion",
								variable: 1,
								query: {
									label: "Bastion X",
									request: {
										type: "directives",
										slug: "bastion"
									}
								}
							}
						]
					});

					const special = target.listEntry.tagGroups.find(g => g.slug === "special-rules");
					special.tags.push({
						name: "You and What Army?",
						slug: "you-and-what-army",
						query: {
							label: "You and What Army?",
							request: {
								type: "directives",
								slug: "you-and-what-army"
							}
						}
					});
				}
			}
		}
	},
	"KONUNGYR": (node) => {
		const { listEntry } = node;
		if (listEntry.isWarlord) {
			const finds = listEntry.options.groups.find(g => g.slug === "trove-finds");
			if (finds) finds.maxOptions += 1;
		}
	},
	"SHOCK_ASSAULT": (node) => {
		const { listEntry } = node;
		if (listEntry.isWarlord) {
			const regiments = node.parent.children.filter(c => c.type !== "character");
			if (regiments) {
				regiments.forEach(r => {
					const group = r.listEntry.tagGroups?.find(g => g.slug === "special-rules");
					const shock = group?.tags?.find(t => t.slug === "shock")
					if (!shock) {
						group.tags.push({
							name: "Shock",
							slug: "shock",
							query: {
								label: "Shock",
								request: {
									type: "directives",
									slug: "shock"
								}
							}
						})
					}
				})
			}
		}
	},
	"APEX_MASTER": (node, params, source, list) => {
		const { listEntry } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "apex-master-mount");
		if (selector) {
			const predators = node.parent.children.filter(c => c.listEntry.slug === "apex-predator");
			selector.targets = predators;
			selector.active = true;

			if (selector.selections) {
				const targetId = selector.selections[0];
				const target = node.parent.children.find(c => c.id === targetId);
				if (target) {
					target.listEntry.tagGroups.push({
						name: "Draw Events",
						slug: "draw-event",
						tags: [
							{
								name: "Bastion",
								slug: "bastion",
								variable: 1,
								query: {
									label: "Bastion X",
									request: {
										type: "directives",
										slug: "bastion"
									}
								}
							}
						]
					});

					const special = target.listEntry.tagGroups.find(g => g.slug === "special-rules");
					special.tags.push({
						name: "You and What Army?",
						slug: "you-and-what-army",
						query: {
							label: "You and What Army?",
							request: {
								type: "directives",
								slug: "you-and-what-army"
							}
						}
					});
				}
			}
		}
	},
	"SANCTIFIED_LABARON": (node, params, source, list) => {
		const { listEntry } = node;
		const selector = listEntry.entrySelectors.find(s => s.slug === "sanctified-labaron");
		if (selector) {
			const restricted = node.parent.children.filter(c => c.type === "restricted");
			selector.targets = restricted;
			selector.active = true;

			if (selector.selections) {
				const targetId = selector.selections[0];
				const target = node.parent.children.find(c => c.id === targetId);
				if (target) {
					target.type = "mainstay"
				}
			}
		}
	},
	"STANDARD_BEARER_THRESHOLD": (node, params, source, list) => {
		const { listEntry } = node;
		const rule = listEntry.rules?.find(r => r.type === "STANDARD_BEARER");
		if (rule) {
			rule.params.threshold = params.threshold;
		}
	},
	"RAJAKUR_RETINUE": (node) => {
		const { listEntry, selections } = node;
		const countRajakur = selections.filter(s => s === "rajakur-corps").length;

		if (countRajakur === 2) {
			listEntry.statline["E"] += 1;
		} else if (countRajakur === 3) {
			console.log(listEntry)
			const tagGroups = listEntry?.tagGroups || listEntry?.profiles?.[0]?.tagGroups;
			const group = tagGroups?.find(g => g.slug === "special-rules");

			if (group) {
				const existingTag = group.tags.find(e => e.slug === "wizard")
				if (existingTag) {
					existingTag.variable += 1;
				} else {
					group.tags.push({
						"name": "Wizard",
						"query": {
							"label": "Wizard X",
							"request": {
								"slug": "wizard",
								"type": "directives"
							}
						},
						"slug": "wizard",
						"variable": 1
					});
				}
			} else {
				listEntry.tagGroups ||= [];
				listEntry.tagGroups.push({
					"name": "Special Rules",
					"slug": "special-rules",
					"tags": [
						{
							"name": "Wizard",
							"query": {
								"label": "Wizard X",
								"request": {
									"slug": "wizard",
									"type": "directives"
								}
							},
							"slug": "wizard",
							"variable": 1
						}
					]
				});
			}
		}
	},
}

function countSlug(node, slug) {
	let local = node.selections.filter(e => e === slug).length;
	node.children.forEach(c => {
		local += countSlug(c, slug);
	})
	return local;
}


export default FB_EFFECTS