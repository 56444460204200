import { Checkbox, FormControlLabel, IconButton, List, ListItem, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import FancyTypography from "../../ui/FancyTypography";
import ListEntryOptions from "./ListEntryOptions";
import EntryTags from "../entry/panel/EntryTags";
// import Warband from "./Warband";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useContext, useRef, useState, useEffect } from "react";
import GameCtx from "../../store/GameCtx";
import { Box } from "@mui/system";
import ListCtx from "../../store/ListCtx";
import { useDispatch } from "react-redux";
import { NotListedLocationOutlined } from "@mui/icons-material";
import { updateData, updateOptions } from "../../store/list-slice";
import QueryIcon from "../query/QueryIcon";
import TagGroup from "../bits/TagGroup";
import StepperField from "../../ui/StepperField";
import useGameSettings from "../../hooks/use-game-settings";
import EntrySelector from "./EntrySelector";


function ListEntryContent({ listNode, activeProfileIndex = null, onProfileChange }) {
	const { path, listEntry, selections, sizeSteps = 0 } = listNode;
	const {
		options = {},
		minSize,
		maxSize,
		size,
		sizeStep,
		sizeStepCost,
		slug,
		canBeWarlord,
		isWarlord,
		profiles,
		texts,
		entrySelectors = [],
	} = listEntry;
	const { orphans: orphanOptions = [], groups: optionGroups = [] } = options;
	const activeProfile = activeProfileIndex === null ? null : profiles[activeProfileIndex];
	const tagGroups = activeProfile?.tagGroups || listEntry.tagGroups;
	const hasTags = tagGroups?.length > 0;
	const canIncreaseSize = maxSize > minSize;
	const hasOptions = canIncreaseSize || canBeWarlord ||
		orphanOptions.length > 0 || optionGroups.length > 0;
	const dispatch = useDispatch();
	const { getGameSettings, getComponent } = useGameSettings();
	const { entryUnitPlural, entryUnit } = getGameSettings();

	const standsRef = useRef();

	function handleProfileChange(event, name) {
		if (name) {
			const index = profiles.findIndex(p => p.profileName === name);
			onProfileChange(index);
		}
	}


	function handleOptionsUpdate(group) {
		// const index = entry.optionGroups.findIndex(g => g.slug === group.slug);
		// if (index === -1) return;

		// onUpdate({
		// 	...entry,
		// 	optionGroups: [
		// 		...entry.optionGroups.slice(0, index),
		// 		group,
		// 		...entry.optionGroups.slice(index + 1),
		// 	],
		// });

		// if (group.slug === "retinue") {
		// 	let updates = [];
		// 	const options = group.subgroups.reduce((prev, s) => prev.concat(s.options.filter(o => o.size > 0)), []);
		// 	for (const o of options) {
		// 		const retinueEntry = currentFaction.entryGroups.find(g => g.slug === "retinues").entries.find(e => e.slug === o.slug)
		// 		if (retinueEntry) {
		// 			updates.push({
		// 				...retinueEntry,
		// 				size: o.size,
		// 				id: `${Date.now()}-${retinueEntry.slug}`,
		// 			});
		// 		}
		// 	}

		// 	onListUpdate({
		// 		retinues: updates
		// 	});
		// }


		// const optionsToAdd = [];
		// if (group.subgroups) {
		// 	const optionsToRemove = [];

		// 	group.subgroups.forEach(s => s.options.forEach(o => {
		// 		optionsToRemove.push(o.slug)
		// 		if (o.selected) optionsToAdd.push(o.slug);
		// 	}));

		// 	dispatch(updateOptions({
		// 		path: listEntry.path,
		// 		add: optionsToAdd,
		// 		remove: optionsToRemove,
		// 	}))
		// } else {
		// 	const optionsToRemove = group.options.map(o => o.slug);
		// 	group.options.forEach(o => {
		// 		if (o.selected) {
		// 			optionsToAdd.push(o.slug);
		// 		}
		// 	});
		// 	dispatch(updateOptions({
		// 		path: listEntry.path,
		// 		add: optionsToAdd,
		// 		remove: optionsToRemove,
		// 	}))
		// }
	}

	function handleWarlordChange(e) {
		dispatch(updateOptions({
			path,
			add: e.target.checked ? ["warlord"] : [],
			remove: e.target.checked ? [] : ["warlord"],
		}));
	}


	function handleStandsUp() {
		// if (standsRef.current.valueAsNumber < maxSize) standsRef.current.valueAsNumber += 1;

		// onUpdate({
		// 	...entry,
		// 	size: standsRef.current.valueAsNumber,
		// });
	}

	function handleStandsDown() {
		// if (standsRef.current.valueAsNumber > minSize) standsRef.current.valueAsNumber -= 1;

		// onUpdate({
		// 	...entry,
		// 	size: standsRef.current.valueAsNumber,
		// });
	}


	const tagsSection = !hasTags ? null : (
		<Stack spacing={1}>
			{tagGroups.map((g) => (
				<TagGroup key={g.slug} group={g} />
			))}
		</Stack>
	);

	const textsSection = !texts ? null : texts.map((t, i) => (
		<Box key={i} component={Paper} variant="outlined" p={1}>
			<Typography dangerouslySetInnerHTML={{ __html: t.content }} />
		</Box>
	));

	const warlordSection = !canBeWarlord ? null : (
		<Box textAlign="center">
			<FormControlLabel
				control={
					<Checkbox
						checked={isWarlord || false}
						onChange={handleWarlordChange}
					/>}
				label={<Typography variant="h5">Select as Warlord</Typography>}
			/>
		</Box>
	);

	function handleSizeUp() {
		if (minSize + (sizeSteps + 1) * sizeStep <= maxSize) {
			// if (size + sizeStep <= maxSize) {
			dispatch(updateData({
				path,
				data: {
					sizeSteps: sizeSteps ? sizeSteps + 1 : 1
				}
			}))
		}
	}

	function handleSizeDown() {
		if (minSize + (sizeSteps - 1) * sizeStep >= minSize) {
			// if (size - sizeStep >= minSize) {
			dispatch(updateData({
				path,
				data: {
					sizeSteps: sizeSteps ? sizeSteps - 1 : 0
				}
			}))
		}
	}

	const standsSection = !canIncreaseSize || slug === "war-chariots" || slug === "modular-regiment" ? null : (
		<Box textAlign="center">
			<Typography variant="h5">{`Additional ${entryUnitPlural} [${sizeStepCost} each]`}</Typography>
			<StepperField
				size="small"
				fullWidth
				value={minSize + sizeSteps * sizeStep}
				// onChange={handlePointsChange}
				onStepUp={handleSizeUp}
				onStepDown={handleSizeDown}
				sx={{
					maxWidth: "200px"
				}}
			/>
		</Box>
		// 		<Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
		// 			<Typography variant="h5" flex="1 1 0" textAlign="right">{currentGameSlug === "tlaok" ? "EntryCount:" : "Models:"}</Typography>
		// 			<TextField
		// 				size="small"
		// 				type="number"
		// 				inputRef={standsRef}
		// 				defaultValue={size}
		// 				InputProps={{
		// 					readOnly: true,
		// 					startAdornment:
		// 						<IconButton onClick={handleStandsDown}>
		// 							<RemoveCircleOutlineIcon />
		// 						</IconButton>,
		// 					endAdornment:
		// 						<IconButton onClick={handleStandsUp} >
		// 							<AddCircleOutlineIcon />
		// 						</ IconButton>,
		// 				}}
		// 				sx={{
		// 					alignSelf: "center",
		// 					maxWidth: "200px",
		// 					"& input[type=number]": {
		// 						MozAppearance: 'textfield',
		// 						textAlign: "center",
		// 					},
		// 					"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
		// 						WebkitAppearance: "none",
		// 						margin: 0
		// 					},
		// 				}}
		// 			/>
		// 			<Box flex="1 1 0"></Box >
		// 		</Stack >
	);
	const selectorsSection = entrySelectors.filter(s => s.active !== false).map(s => <EntrySelector key={s.slug} node={listNode} selector={s} />);

	const optionGroupsSection = !optionGroups.length ? null : (
		<Stack spacing={1}>
			{optionGroups.filter(g => g.active !== false).map(g => {
				const Component = getComponent("list-entry-options", g.slug);
				return <Component key={g.slug} node={listNode} group={g} />;
			})}
		</Stack>
	)
	// 	< ListEntryOptions optionGroups = { optionGroups } onUpdate = { handleOptionsUpdate } listEntry = { listEntry } />
	// 	<React.Fragment key={g.slug}>
	// 	{(g.slug === "retinue" && currentGameSlug === "fb") ?
	// 		<RetinueLabelledGroup key={g.name} group={g} onUpdate={onUpdate} /> :
	// 		<ListEntryLabelledGroup key={g.name} group={g} onUpdate={onUpdate} listEntry={listEntry} />
	// 	}
	// </React.Fragment>

	function handleOptionToggle(option, event) {
		const selected = event.target.checked;

		dispatch(updateOptions({
			path,
			add: selected ? [option.slug] : [],
			remove: selected ? [] : [option.slug],
		}));
	}


	const visibleOrphans = orphanOptions.filter(o => o.active !== false);
	const orphansSection = !visibleOrphans.length ? null : (
		<List disablePadding sx={{ px: 1 }}>
			{visibleOrphans.map(o => (
				<ListItem key={o.slug} disablePadding>
					<FormControlLabel
						control={
							<Checkbox
								checked={selections.includes(o.slug)}
							/>
						}
						label={o.name}
						onChange={handleOptionToggle.bind(null, o)}
					/>
					{o.query && <QueryIcon query={o.query} />}
					<Typography
						variant="body1"
						flex="1 1 auto"
						align="right"
					>
						{o.cost || "Free"}
					</Typography>
				</ListItem>
			))}
		</List>
	);

	return (
		<>
			{activeProfile && (
				<ToggleButtonGroup
					exclusive
					value={activeProfile.profileName}
					size="small"
					fullWidth
					onChange={handleProfileChange}
					sx={{
						"& .MuiToggleButton-root": {
							fontSize: "16px",
							p: 0.5
						}
					}}
				>
					{profiles.map(p => (
						<ToggleButton key={p.profileName} value={p.profileName} disabled={p.active === false}>{p.profileName}</ToggleButton>
					))}
				</ToggleButtonGroup>
			)
			}
			<Stack spacing={3}>
				{tagsSection}
				{textsSection}
				{hasOptions && (
					<FancyTypography variant="h5">Options</FancyTypography>
				)}
				{warlordSection}
				{standsSection}
				{orphansSection}
				{selectorsSection}
				{optionGroupsSection}
			</Stack>
		</>
	);
}


export default ListEntryContent;